import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconFilterPlus = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M20,4.5V6.76a1.5,1.5,0,0,1-.44,1.06l-.06.06a.288.288,0,0,1-.21.09.5.5,0,0,1-.12-.02,5.491,5.491,0,0,0-5.3,9.561.292.292,0,0,1,.128.24V21l-4-3V14a1.5,1.5,0,0,0-.44-1.062L4.44,7.82A1.5,1.5,0,0,1,4,6.758V4.5A1.5,1.5,0,0,1,5.5,3h13A1.5,1.5,0,0,1,20,4.5Z'
        />
        <path
          fill={fill}
          d='M17,9a4,4,0,1,0,4,4A4,4,0,0,0,17,9Zm1.25,4.5H17.5v.75a.5.5,0,0,1-1,0V13.5h-.75a.5.5,0,0,1,0-1h.75v-.75a.5.5,0,0,1,1,0v.75h.75a.5.5,0,0,1,0,1Z'
        />
      </g>
    </svg>
  )
}

export default IconFilterPlus
