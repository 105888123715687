import React, { useEffect } from 'react'
import styles from './ManagerHeader.module.scss'
import HeaderLogo from '../../ui/headerLogo/HeaderLogo'
import { ManagerProfileDrop } from '../../../menu/dropdown/managerProfile/managerProfile'
import { NavLink, useNavigate } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import { getTestsCount } from '../../../store/tests/thunk'
import { useAppDispatch } from '../../../store/hooks'
import { useTestsCountSelector } from '../../../store/selectors/tests'
import {
  IconAngleDown,
  IconClipboardEdit,
  IconLightbulb,
  IconMoon,
  IconPrint,
  IconReceiptText,
} from '../../../utils/icons'
import { Menu, Space, Switch } from 'antd'
import { useThemeSelector } from '../../../store/selectors/theme'
import { setTheme } from '../../../store/theme/reducer'
import { useCrmPermission } from '../../../store/selectors/crmInfo'
import DropdownUI from '../../ui/DropDownUI/DropdownUi'

const ManagerHeader: React.FC = () => {
  const dispatch = useAppDispatch()
  const testsCount = useTestsCountSelector()
  const theme = useThemeSelector()
  const permissions = useCrmPermission()

  useEffect(() => {
    dispatch(getTestsCount()).then()
  }, [])

  const navigate = useNavigate()

  const onChange = (checked: boolean) => {
    dispatch(setTheme({ theme: checked ? 'dark' : 'light' }))
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <span className={styles.logo}>
          <HeaderLogo />
        </span>

        <div className={styles.actions}>
          <div className={styles.switcher}>
            <Switch
              defaultChecked={theme === 'dark'}
              onChange={onChange}
              checkedChildren={<IconMoon width='16' height='16' fill='var(--color-mainBg)' />}
              unCheckedChildren={<IconLightbulb width='16' height='16' fill='var(--color-main)' />}
            />
          </div>

          {permissions.scripts && (
            <div className={styles.btnWrapper}>
              <div className={styles.btn} onClick={() => navigate(routersPath.MANAGER_SCRIPTS)}>
                <IconReceiptText width='22' height='22' fill='var(--color-main)' />
                Мои скрипты
              </div>
            </div>
          )}

          {permissions.generator && (
            <div className={styles.btnWrapper}>
              <DropdownUI
                menu={
                  <Menu
                    className='v2'
                    items={[
                      {
                        key: routersPath.DOC_GEN,
                        label: <NavLink to={routersPath.DOC_GEN}>Генератор документов</NavLink>,
                        icon: <IconPrint width='22' height='22' fill='var(--color-main)' />,
                      },
                      {
                        key: routersPath.NEW_DOC_GEN,
                        label: (
                          <NavLink to={routersPath.NEW_DOC_GEN}>Новый генератор документов</NavLink>
                        ),
                        icon: <IconPrint width='22' height='22' fill='var(--color-main)' />,
                      },
                    ]}
                  />
                }
              >
                <Space>
                  <IconPrint width='22' height='22' fill='var(--color-main)' />
                  Генератор документов
                  <IconAngleDown width='16' height='16' fill='#fff' />
                </Space>
              </DropdownUI>
            </div>
          )}

          {permissions.tests && (
            <div className={styles.btnWrapper} onClick={() => navigate(routersPath.MANAGER_TESTS)}>
              <div className={styles.btn}>
                <IconClipboardEdit width='22' height='22' fill='var(--color-main)' />
                Тесты
              </div>
              {testsCount > 0 && <div className={styles.tag}>{testsCount}</div>}
            </div>
          )}

          <div className={styles.dropdown}>
            <ManagerProfileDrop />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManagerHeader
