import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconFilePlus = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M17,9a2.652,2.652,0,0,1-3-3V3H8A2.652,2.652,0,0,0,5,6V18a2.652,2.652,0,0,0,3,3h9a2.652,2.652,0,0,0,3-3V9Z'
        />
        <path fill={fill} d='M17,9h3L14,3V6A2.652,2.652,0,0,0,17,9Z' />
        <path
          fill={fill}
          d='M15,13.25H13.25V11.5a.75.75,0,0,0-1.5,0v1.75H10a.75.75,0,0,0,0,1.5h1.75V16.5a.75.75,0,0,0,1.5,0V14.75H15a.75.75,0,0,0,0-1.5Z'
        />
      </g>
    </svg>
  )
}

export default IconFilePlus
