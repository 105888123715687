import React from 'react'
import { InputProps } from 'antd/lib/input/Input'
import styles from './WithLabel.module.scss'

interface Props extends InputProps {
  children?: JSX.Element
  label?: string
  icon?: JSX.Element
}

const WithLabel: React.FC<Props> = ({ children, label, icon, className, ...props }) => {
  return (
    <div className={styles.container}>
      {label && (
        <div className={styles.label}>
          {icon}
          {label}
        </div>
      )}
      {children && React.cloneElement(children, props)}
    </div>
  )
}

export default WithLabel
