import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconClipboardList = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M16,5h1a2.652,2.652,0,0,1,3,3V18a2.652,2.652,0,0,1-3,3H7a2.652,2.652,0,0,1-3-3V8A2.652,2.652,0,0,1,7,5H8'
        />
        <path
          fill={fill}
          d='M16,4.5v1A1.326,1.326,0,0,1,14.5,7h-5A1.326,1.326,0,0,1,8,5.5v-1A1.326,1.326,0,0,1,9.5,3h5A1.326,1.326,0,0,1,16,4.5Z'
        />
        <path
          fill={fill}
          d='M8.75,12A.75.75,0,1,1,8,11.25.75.75,0,0,1,8.75,12ZM8,15.25a.75.75,0,1,0,.75.75A.75.75,0,0,0,8,15.25ZM16.75,12a.75.75,0,0,0-.75-.75H11a.75.75,0,0,0,0,1.5h5A.75.75,0,0,0,16.75,12Zm0,4a.75.75,0,0,0-.75-.75H11a.75.75,0,0,0,0,1.5h5A.75.75,0,0,0,16.75,16Z'
        />
      </g>
    </svg>
  )
}

export default IconClipboardList
