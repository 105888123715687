import React, { useCallback } from 'react'
import { ITransaction } from './payment.interface'
import styles from './PayModal.module.scss'
import { Form, Input } from 'antd'
import ButtonUi from '../../ui/ButtonUi/ButtonUi'
import axiosInstance from '../../../config/axios'
import { serverPath } from '../../../constants/api-endpoints'
import { errorMapper } from '../../../utils/errorMapper'
import { ErrorMessages } from '../../../constants/errors'
import { useMessage } from '../../../hooks/useMessage'
import WithLabel from '../../ui/WithLabel/WithLabel'
import { IconDollar, IconInfoCircle } from '../../../utils/icons'
import BadgeUi from '../../ui/BadgeUi/BadgeUi'
import { useCrmSelector } from '../../../store/selectors/auth'

interface Props {
  setTransaction: React.Dispatch<React.SetStateAction<ITransaction | undefined>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const AddTransaction: React.FC<Props> = ({ setTransaction, setLoading }) => {
  const { success, error } = useMessage()
  const crm = useCrmSelector()

  const submit = useCallback(async (e: { amount: number }) => {
    setLoading(true)
    axiosInstance
      .post<ITransaction>(serverPath.BILLING, { amount: Number(e.amount) })
      .then(({ data }) => {
        setTransaction(data)
        success('Заявка добавлена')
      })
      .catch((e) => {
        error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <Form name='add_transaction' onFinish={submit} className={styles.addWrapper}>
      <div className={styles.header}>Создать заявку на оплату:</div>
      <Form.Item
        className={styles.input}
        name={'amount'}
        rules={[{ required: true, message: 'Введите сумму' }]}
      >
        <WithLabel
          label={'Введите сумму USDT (TRC20):'}
          icon={<IconDollar width='18' height='18' fill='var(--color-main)' />}
        >
          <Input placeholder={`Введите сумму`} type='number' min={1} />
        </WithLabel>
      </Form.Item>

      <BadgeUi color='blue'>
        <IconInfoCircle width='20' height='20' fill='var(--color-blue)' />* Минимальная сумма
        пополнения 250 долларов.
        {Boolean(crm?.price) && (
          <>
            <br />
            &#160;&#160;&#160;Ваш месячный тариф: {crm?.price} usdt
          </>
        )}
      </BadgeUi>

      <div className={styles.actions}>
        <ButtonUi htmlType='submit'>Cоздать заявку</ButtonUi>
      </div>
    </Form>
  )
}

export default AddTransaction
