import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconPrint = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path fill={fill} d='M17,7V5L15,3H8A1,1,0,0,0,7,4V7' />
        <path
          fill={fill}
          opacity={0.3}
          d='M7,17H4.5A1.5,1.5,0,0,1,3,15.5V10A2.652,2.652,0,0,1,6,7H18a2.652,2.652,0,0,1,3,3v5.5A1.5,1.5,0,0,1,19.5,17H17'
        />
        <path fill={fill} d='M8,21h8a1,1,0,0,0,1-1V15H7v5A1,1,0,0,0,8,21Z' />
        <circle fill={fill} cx='17' cy='11' r='1' />
      </g>
    </svg>
  )
}

export default IconPrint
