import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconClipboardEdit = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M16,5h1a2.652,2.652,0,0,1,3,3V18a2.652,2.652,0,0,1-3,3H7a2.652,2.652,0,0,1-3-3V8A2.652,2.652,0,0,1,7,5H8'
        />
        <path
          fill={fill}
          d='M16,4.5v1A1.326,1.326,0,0,1,14.5,7h-5A1.326,1.326,0,0,1,8,5.5v-1A1.326,1.326,0,0,1,9.5,3h5A1.326,1.326,0,0,1,16,4.5Z'
        />
        <path
          fill={fill}
          d='M11.98,11.78l2.24,2.24-4,3.98H8V15.78Zm3.76-.53-.99-.99a.887.887,0,0,0-1.26,0l-.81.82,2.24,2.24.82-.81A.887.887,0,0,0,15.74,11.25Z'
        />
      </g>
    </svg>
  )
}

export default IconClipboardEdit
