import { createContext, Dispatch, useContext } from 'react'
import { Socket } from 'socket.io-client'

const contextInitial = {
  socket: null,
  emit: () => {
    return
  },
  subscribeToRecall: () => {
    return
  },
  unSubscribeToRecall: () => {
    return
  },
  usersStatus: [],
  updateRecall: 0,
  updateTransmit: 0,
}

export interface ISocketContext {
  socket: Socket | null
  emit(event: string, data: any): void
  subscribeToRecall(cb: Dispatch<any>): void
  unSubscribeToRecall(cb: Dispatch<any>): void
  usersStatus: string[]
  updateRecall: number
  updateTransmit: number
}

export const SocketContext = createContext<ISocketContext>(contextInitial)
export const useSocketContext = (): ISocketContext => useContext(SocketContext)
