import React from 'react'
import { NavLink } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import { IconDollarReceiveSquare, IconDollarSendSquare, IconSackDollar } from '../../../utils/icons'

export const salaryTabs = [
  {
    show: true,
    title: (
      <NavLink to={routersPath.SALARY_INPUT}>
        <IconDollarSendSquare width='22' height='22' fill='var(--color-main)' />
        Приход
      </NavLink>
    ),
    pages: [routersPath.SALARY_INPUT],
  },
  {
    show: true,
    title: (
      <NavLink to={routersPath.SALARY_OUTPUT}>
        <IconDollarReceiveSquare width='22' height='22' fill='var(--color-main)' />
        Расход
      </NavLink>
    ),
    pages: [routersPath.SALARY_OUTPUT],
  },
  {
    show: true,
    title: (
      <NavLink to={routersPath.SALARY_CRYPTO_CHANGE}>
        <IconSackDollar width='22' height='22' fill='var(--color-main)' />
        Вывод крипты/Расчет ЗП
      </NavLink>
    ),
    pages: [routersPath.SALARY_CRYPTO_CHANGE],
  },
]
