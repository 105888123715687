import React, { memo, useCallback } from 'react'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import styles from './HeaderLogo.module.scss'
import { useNavigate } from 'react-router-dom'
import { useDefaultPath } from '../../../hooks/useDefautPath'

const HeaderLogo: React.FC = memo(() => {
  const navigate = useNavigate()
  const redirect = useDefaultPath()

  const handler = useCallback(() => {
    navigate(redirect)
  }, [redirect])

  return (
    <div className={styles.container} onClick={handler}>
      <Logo className={styles.logo} />
    </div>
  )
})

export default HeaderLogo
