import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconFileImport = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M17,9a2.652,2.652,0,0,1-3-3V3H8A2.652,2.652,0,0,0,5,6V18a2.652,2.652,0,0,0,3,3h9a2.652,2.652,0,0,0,3-3V9Z'
        />
        <path fill={fill} d='M17,9h3L14,3V6A2.652,2.652,0,0,0,17,9Z' />
        <path
          fill={fill}
          d='M14,14.25H10.811l.719-.72a.75.75,0,0,0-1.06-1.06l-2,2a.771.771,0,0,0-.162.244.751.751,0,0,0,.162.817l2,2a.75.75,0,1,0,1.06-1.06l-.719-.72H14a.75.75,0,0,0,0-1.5Z'
        />
      </g>
    </svg>
  )
}

export default IconFileImport
