import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconAlarmClock = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <circle fill={fill} opacity={0.3} cx='12' cy='12' r='8' />
        <path
          fill={fill}
          d='M18.531,20.471a.75.75,0,1,1-1.062,1.058l-2.218-2.224a7.9,7.9,0,0,0,1.352-.769Zm-13.062,0a.75.75,0,0,0,1.062,1.06l2.218-2.225A7.9,7.9,0,0,1,7.4,18.536ZM21.536,5.525a.752.752,0,0,0-.011-1.061l-2-1.96a.75.75,0,0,0-1.05,1.072l2,1.96a.751.751,0,0,0,1.061-.011ZM3.53,5.53l2-2A.75.75,0,0,0,4.47,2.47l-2,2A.75.75,0,1,0,3.53,5.53Z'
        />
        <path
          fill={fill}
          d='M14,14.79a.744.744,0,0,1-.53-.22l-2-2a.747.747,0,0,1-.22-.53v-4a.75.75,0,0,1,1.5,0v3.689l1.78,1.781A.75.75,0,0,1,14,14.79Z'
        />
      </g>
    </svg>
  )
}

export default IconAlarmClock
