import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconInfoCircle = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <circle fill={fill} opacity={0.3} cx='12' cy='12' r='10' />
        <path
          fill={fill}
          d='M12,17.25a.75.75,0,0,1-.75-.75V11.929a.75.75,0,0,1,1.5,0V16.5A.75.75,0,0,1,12,17.25ZM13.02,8.5a1,1,0,0,0-1-1h-.01a1,1,0,1,0,1.01,1Z'
        />
      </g>
    </svg>
  )
}

export default IconInfoCircle
