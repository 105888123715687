import React from 'react'
import { NavLink } from 'react-router-dom'
import { Menu } from 'antd'
import { routersPath } from '../../../../router/routes-path'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import {
  IconChartBar,
  IconLogOut,
  IconDollar,
  IconReceiptText,
  IconUser,
  IconUsersGroup,
  IconPowerOff,
} from '../../../../utils/icons'
import { useCrmPermission } from '../../../../store/selectors/crmInfo'

export const AdminProfile = (handler: MenuClickEventHandler) => {
  const permissions = useCrmPermission()
  return (
    <Menu
      onClick={handler}
      items={[
        {
          key: 'profile',
          label: <span>Профиль</span>,
          icon: <IconUser width='20' height='20' fill='var(--color-main)' />,
        },
        {
          disabled: !permissions.statistic,
          key: routersPath.STAT_PAGE,
          label: (
            <NavLink to={routersPath.STAT_PAGE}>
              <span>Статистика</span>
            </NavLink>
          ),
          icon: <IconChartBar width='20' height='20' fill='var(--color-main)' />,
        },
        {
          key: routersPath.MANAGERS_PAGE,
          label: (
            <NavLink to={routersPath.MANAGERS_PAGE}>
              <span>Менеджеры</span>
            </NavLink>
          ),
          icon: <IconUsersGroup width='20' height='20' fill='var(--color-main)' />,
        },
        {
          disabled: !permissions.scripts,
          key: routersPath.SCRIPTS_PAGE,
          label: (
            <NavLink to={routersPath.SCRIPTS_PAGE}>
              <span>Скрипты</span>
            </NavLink>
          ),
          icon: <IconReceiptText width='20' height='20' fill='var(--color-main)' />,
        },
        {
          key: 'pay',
          label: <span>Оплатить CRM</span>,
          icon: <IconDollar width='20' height='20' fill='var(--color-main)' />,
        },
        {
          key: 'powerOff',
          label: <span>Выключить CRM</span>,
          icon: <IconPowerOff width='20' height='20' fill='var(--color-main)' />,
        },
        {
          disabled: false,
          key: 'divider',
          type: 'divider',
        },
        {
          key: 'exit',
          label: <span>Выход</span>,
          icon: <IconLogOut width='20' height='20' fill='var(--color-main)' />,
        },
      ].filter((el) => !el.disabled)}
    />
  )
}
