export enum BaseColumn {
  FULL_NAME = 'fullName',
  CITY = 'city',
  REGION = 'region',
  ADDRESS = 'address',
  AGE = 'age',
  PHONE = 'phone',
  TIME_ZONE = 'timezone',
  ADDITION_FIELD_1 = 'additionField1',
  ADDITION_FIELD_2 = 'additionField2',
  ADDITION_FIELD_3 = 'additionField3',
  ADDITION_FIELD_4 = 'additionField4',
  ADDITION_FIELD_5 = 'additionField5',
  ADDITION_FIELD_6 = 'additionField6',
  ADDITION_FIELD_7 = 'additionField7',
  ADDITION_FIELD_8 = 'additionField8',
  ADDITION_FIELD_9 = 'additionField9',
  ADDITION_FIELD_10 = 'additionField10',
  ADDITION_INFO = 'additionInfo',
}

export enum BaseDefaultStatusesColumn {
  SMARTERME = 'smarterme',
  NOMONEY = 'nomoney',
  SLIV = 'sliv',
  RETRIEVED = 'retrieved',
  UNDERCALL = 'underCall',
  REMOVE = 'remove',
  UNDEFINED = 'undefined',
  REMAINDER = 'remainder',
  PENDING = 'pending',
  IN_WORK = 'inwork',
  RECALL = 'recall',
  TRANSMIT = 'transmit',
}
