import React from 'react'
import { EUserRoles } from '../enums/roles'
import { Navigate } from 'react-router'
import { routersPath } from './routes-path'
import { useUserSelector } from '../store/selectors/auth'

interface PrivateRouteProps {
  children: JSX.Element | null
  roles?: EUserRoles[]
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, roles }) => {
  const user = useUserSelector()
  const userHasRequiredRole = user ? roles?.includes(user.role) : false
  return userHasRequiredRole && children ? <>{children}</> : <Navigate to={routersPath.NOT_FOUND} />
}

export default PrivateRoute
