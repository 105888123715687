import { AppDispatch } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { serverPath } from '../../constants/api-endpoints'
import { ISettingsStatus } from '../../interfaces/settings'
import { setStatuses } from './reducer'

export const getSettings = () => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<ISettingsStatus[]> = await axiosInstance.get(
      serverPath.CRM_SETTINGS,
    )
    dispatch(setStatuses(data))
  } catch (e) {
    console.log(e)
  }
}
