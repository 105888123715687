import { createSlice, Draft } from '@reduxjs/toolkit'
import { ICrmInfoRes } from '../../interfaces/crmInfo'

const initialState: ICrmInfoRes = {
  id: '',
  name: '',
  price: null,
  billingDate: '',
  isActive: true,
  permission: {
    generator: false,
    salary: false,
    statistic: false,
    whiteList: false,
    scripts: false,
    tests: false,
    viber: false,
    telegram: false,
    watsApp: false,
  },
}

export const crmInfoSlice = createSlice({
  name: 'crmInfo',
  initialState,
  reducers: {
    setCrmInfo: (state: Draft<ICrmInfoRes>, { payload }) => {
      state.id = payload.id
      state.name = payload.name
      state.price = payload.price
      state.billingDate = payload.billingDate
      state.isActive = payload.isActive
      state.permission = payload.permission
    },
    clearCrmInfo: (state: Draft<ICrmInfoRes>) => {
      state = initialState
    },
  },
})

export const { setCrmInfo, clearCrmInfo } = crmInfoSlice.actions

export default crmInfoSlice.reducer
