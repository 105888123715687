import React, { Suspense, useEffect, useState } from 'react'
import { getPrice } from '../../../store/currency/thunk'
import { useAppDispatch } from '../../../store/hooks'
import { useCurrencySelector } from '../../../store/selectors/currency'
import BoxUi from '../../ui/BoxUi/BoxUi'
import styles from './salary.module.scss'
import { Outlet, useLocation } from 'react-router-dom'
import useCurrentTab from '../../../hooks/useCurrentTab'
import { Tabs } from 'antd'
import { salaryTabs } from './salary.tabs'
import PageSpinner from '../../ui/PageSpinner/PageSpinner'
import moment from 'moment/moment'
import { SalaryWeekContext } from '../../../context/salaryWeek.context'
import { SalaryStatusesContext } from '../../../context/salaryStatuses.context'
import { SalaryTransferContext } from '../../../context/salaryTransfer.context'
import { SalaryCryptoDto } from '../../../pages/Salary/SalaryCrypto/SalaryCryptoPage'
import { IconBitcoin, IconDollar } from '../../../utils/icons'
import DatePickerUi from '../../ui/DatePickerUi/DatePickerUi'

const { TabPane } = Tabs

const SalaryLayout: React.FC = () => {
  const dispatch = useAppDispatch()
  const { btc, usd } = useCurrencySelector()
  const { pathname } = useLocation()
  const activeTab = useCurrentTab(pathname, salaryTabs)
  const [weekFilter, setWeekFilter] = useState<moment.Moment | null>(moment().startOf('week'))
  const [salaryStatuses, setSalaryStatuses] = useState({ isConfirm: false, isPreview: true })
  const [salaryTransfer, setSalaryTransfer] = useState<SalaryCryptoDto>()

  useEffect(() => {
    dispatch(getPrice()).then()
  }, [])

  return (
    <>
      <BoxUi className={styles.container}>
        <>
          <div className={styles.header}>
            <div className={styles.title}>Зарплаты</div>
            <Tabs className='ant-tabs-salary' defaultActiveKey='0' activeKey={String(activeTab)}>
              {salaryTabs.map((el, index) => (
                <TabPane tab={el.title} key={index} />
              ))}
            </Tabs>
          </div>
          <div className={styles.weekSelect}>
            <p>Выбор недели:</p>
            <DatePickerUi
              allowClear={false}
              onChange={(month) => setWeekFilter(month)}
              picker='week'
              value={weekFilter}
            />
          </div>
          <div className={styles.curse}>
            <p>
              <IconBitcoin width='20' height='20' fill='var(--color-main)' />
              Курс BTC: <span className={styles.count}>{btc} $</span>
            </p>
            <p>
              <IconDollar width='20' height='20' fill='var(--color-main)' />
              Курс USD: <span className={styles.count}>{usd} грн</span>
            </p>
          </div>
        </>
      </BoxUi>
      <SalaryWeekContext.Provider value={{ weekFilter, setWeekFilter }}>
        <SalaryStatusesContext.Provider value={{ salaryStatuses, setSalaryStatuses }}>
          <SalaryTransferContext.Provider value={{ salaryTransfer, setSalaryTransfer }}>
            <Suspense fallback={<PageSpinner className={styles.loadSpin} />}>
              <Outlet />
            </Suspense>
          </SalaryTransferContext.Provider>
        </SalaryStatusesContext.Provider>
      </SalaryWeekContext.Provider>
    </>
  )
}

export default SalaryLayout
