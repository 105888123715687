import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconImage = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M18,20.75H6A3.383,3.383,0,0,1,2.25,17V7A3.383,3.383,0,0,1,6,3.25H18A3.383,3.383,0,0,1,21.75,7V17A3.383,3.383,0,0,1,18,20.75Z'
        />
        <path
          fill={fill}
          d='M20.162,14.162,15.71,9.71a1.006,1.006,0,0,0-1.42,0L9.35,14.65a.5.5,0,0,1-.7,0l-.94-.94a1.006,1.006,0,0,0-1.42,0L3.838,16.162a.3.3,0,0,0-.088.212V17c0,1.58.67,2.25,2.25,2.25H18c1.58,0,2.25-.67,2.25-2.25V14.374A.3.3,0,0,0,20.162,14.162Z'
        />
        <path fill={fill} d='M8,10.25a1.25,1.25,0,0,1-.009-2.5H8a1.25,1.25,0,0,1,0,2.5Z' />
      </g>
    </svg>
  )
}

export default IconImage
