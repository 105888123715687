import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconUsersGroup = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M13.785,13.389h-3.57A4.662,4.662,0,0,0,5.25,18.35,2.857,2.857,0,0,0,8.433,21.5h7.134a2.857,2.857,0,0,0,3.183-3.15A4.662,4.662,0,0,0,13.785,13.389Z'
        />
        <path
          fill={fill}
          d='M12.008,11.512a3.506,3.506,0,1,0-3.52-3.506A3.518,3.518,0,0,0,12.008,11.512Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M22.5,12.65a2.186,2.186,0,0,1-2.43,2.42h-.42a.3.3,0,0,1-.28-.18,5.612,5.612,0,0,0-3.73-2.78.3.3,0,0,1-.16-.5,4.886,4.886,0,0,0,1.41-2.45.3.3,0,0,1,.29-.23h1.61A3.493,3.493,0,0,1,22.5,12.65Z'
        />
        <path
          fill={fill}
          d='M20.18,5.17a2.678,2.678,0,0,1-2.67,2.68c-.074,0-.147,0-.218-.01a.31.31,0,0,1-.207-.087.258.258,0,0,1-.078-.171,4.906,4.906,0,0,0-1.739-3.371.254.254,0,0,1-.105-.153.272.272,0,0,1,.021-.2,2.671,2.671,0,0,1,5,1.314Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M1.5,12.65a2.186,2.186,0,0,0,2.43,2.42h.42a.3.3,0,0,0,.28-.18,5.612,5.612,0,0,1,3.73-2.78.3.3,0,0,0,.16-.5A4.886,4.886,0,0,1,7.11,9.16a.3.3,0,0,0-.29-.23H5.21A3.493,3.493,0,0,0,1.5,12.65Z'
        />
        <path
          fill={fill}
          d='M3.82,5.17A2.678,2.678,0,0,0,6.49,7.85c.074,0,.147,0,.218-.01a.31.31,0,0,0,.207-.087.258.258,0,0,0,.078-.171A4.906,4.906,0,0,1,8.732,4.211a.254.254,0,0,0,.105-.153.272.272,0,0,0-.021-.2,2.671,2.671,0,0,0-5,1.314Z'
        />
      </g>
    </svg>
  )
}

export default IconUsersGroup
