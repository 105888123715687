import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconBoltSquareAlt = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M3,17.625V6.375Q3,3,6.375,3h11.25Q21,3,21,6.375v11.25Q21,21,17.625,21H6.375Q3,21,3,17.625Z'
        />
        <path
          fill={fill}
          d='M16.62,11.236a.374.374,0,0,0-.343-.222H13.6V6.5a.375.375,0,0,0-.654-.251l-5.5,6.111a.375.375,0,0,0,.279.626H10.4V17.5a.376.376,0,0,0,.241.35.375.375,0,0,0,.413-.1l5.5-6.111A.376.376,0,0,0,16.62,11.236Z'
        />
      </g>
    </svg>
  )
}

export default IconBoltSquareAlt
