import React from 'react'
import { Button, ButtonProps } from 'antd'
import cn from 'classnames'
import './ButtonUi.scss'

interface Props extends ButtonProps {
  color?: 'red' | 'green' | 'main' | 'yellow' | 'grey'
  outlined?: boolean
}

const ButtonUi: React.FC<Props> = ({ outlined, color = 'main', className, children, ...props }) => {
  return (
    <Button
      {...props}
      className={cn(
        className,
        'buttonUi',
        {
          outlined: outlined,
        },
        `buttonUi_${color}`,
      )}
    >
      {children}
    </Button>
  )
}

export default ButtonUi
