import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconSendRight = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M8.611,20.55l11.408-6.823a2.011,2.011,0,0,0,0-3.454L8.611,3.45A3.027,3.027,0,0,0,4.474,7.632L7.187,12,4.474,16.368A3.027,3.027,0,0,0,8.611,20.55Z'
        />
        <path
          fill={fill}
          d='M13.75,12a.755.755,0,0,1-.75.75H6.72L7.19,12l-.47-.75H13A.755.755,0,0,1,13.75,12Z'
        />
      </g>
    </svg>
  )
}

export default IconSendRight
