import { routersPath } from '../router/routes-path'
import { ITabs } from '../components/SideBar/Tabs/AdminTabs'

const useCurrentTab = (path: string, tabs: ITabs[]) => {
  const findIndex = (path: string, tabs: ITabs[]) => {
    return tabs.findIndex((el) => el.pages.includes(path as routersPath))
  }
  return findIndex(path, tabs)
}

export default useCurrentTab
