import React, { useEffect, useMemo, useState } from 'react'
import axiosInstance from '../../../config/axios'
import { serverPath } from '../../../constants/api-endpoints'
import { errorMapper } from '../../../utils/errorMapper'
import { ErrorMessages } from '../../../constants/errors'
import { useMessage } from '../../../hooks/useMessage'
import { ITransaction } from './payment.interface'
import styles from './PayModal.module.scss'
import { Spin } from 'antd'
import ApiError from './ApiError'
import TransactionCard from './TransactionCard'
import AddTransaction from './AddTransaction'
import { IconRadiation } from '../../../utils/icons'

const PayModal: React.FC = () => {
  const { error } = useMessage()
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(0)
  const [apiError, setApiError] = useState(false)

  const [transaction, setTransaction] = useState<ITransaction>()

  const loadIcon = <IconRadiation width='44' height='44' fill='var(--color-main)' />

  useEffect(() => {
    setApiError(false)
    setLoading(true)
    axiosInstance
      .get<ITransaction>(serverPath.BILLING_CHECK)
      .then(({ data }) => setTransaction(data?.id ? data : undefined))
      .catch((e) => {
        error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
        setApiError(true)
      })
      .finally(() => setLoading(false))
  }, [reload])

  const render = useMemo(() => {
    if (loading) {
      return <Spin indicator={loadIcon} className={styles.loadSpin} />
    }
    if (apiError) {
      return <ApiError setReload={setReload} />
    }
    if (transaction) {
      return (
        <TransactionCard setReload={setReload} transaction={transaction} setLoading={setLoading} />
      )
    }
    return <AddTransaction setTransaction={setTransaction} setLoading={setLoading} />
  }, [transaction, loading])

  return <div className={styles.container}>{render}</div>
}

export default PayModal
