import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconMoon = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          d='M4.269,7.42a8.892,8.892,0,0,0-1.2,5.676A9.159,9.159,0,0,0,20.989,14.2a.345.345,0,0,0-.533-.365,6.652,6.652,0,0,1-4.683,1.1A6.475,6.475,0,0,1,12.36,3.6a.339.339,0,0,0-.217-.6h-.01A9.158,9.158,0,0,0,4.269,7.42Z'
        />
      </g>
    </svg>
  )
}

export default IconMoon
