export enum SocketEvents {
  USERS_STATUS = 'updateUserStatus',
  MSG = 'receive_message',
  RECALL = 'recall',
  UPDATE_RECALL = 'updateRecall',
  CREATE_TRANSMIT = 'createTransmit',
  LOGOUT = 'logout',
  NOTIFICATION = 'notification',
  UPDATE_SETTINGS = 'updatePermission',
  SUBSCRIPTION = 'subscription',
}
