import { AppDispatch } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { serverPath } from '../../constants/api-endpoints'
import { setSubscription } from './reducer'
import { ISubscriptionReq } from '../../interfaces/subscriptions'

export const getSubscription = () => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<{ subscriptions: ISubscriptionReq }> = await axiosInstance.get(
      serverPath.SUBSCRIBE,
    )

    dispatch(setSubscription({ data }))
  } catch (e) {
    console.log(e)
  }
}
