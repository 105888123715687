import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconUserSlash = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M15.008,12.564a.5.5,0,0,0-.421.143l-7.851,7.85a.5.5,0,0,0,.245.841A4.7,4.7,0,0,0,8,21.5h8a3.148,3.148,0,0,0,3.5-3.48C19.5,15.68,18.32,13.017,15.008,12.564Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M11.36,12.64,4.74,19.26a.418.418,0,0,1-.14-.24,4.521,4.521,0,0,1-.1-1c0-2.55,1.44-5.52,5.5-5.52h1A.575.575,0,0,1,11.36,12.64Z'
        />
        <path
          fill={fill}
          d='M21.53,2.47a.749.749,0,0,0-1.06,0L15.96,6.979a3.791,3.791,0,0,0,.049-.479,4,4,0,1,0-4,4,3.985,3.985,0,0,0,.479-.048L1.47,21.47a.75.75,0,1,0,1.06,1.06l19-19A.749.749,0,0,0,21.53,2.47Z'
        />
      </g>
    </svg>
  )
}

export default IconUserSlash
