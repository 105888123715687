import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconFolderPlus = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21,10v7a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V7A2.652,2.652,0,0,1,6,4h4l3,3h5A2.652,2.652,0,0,1,21,10Z'
        />
        <path
          fill={fill}
          d='M14.5,12.75H12.75V11a.75.75,0,0,0-1.5,0v1.75H9.5a.75.75,0,0,0,0,1.5h1.75V16a.75.75,0,0,0,1.5,0V14.25H14.5a.75.75,0,0,0,0-1.5Z'
        />
      </g>
    </svg>
  )
}

export default IconFolderPlus
