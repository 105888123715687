import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconBellOn = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M20.391,17.688A9.481,9.481,0,0,1,18.5,12.5V8.995a6.495,6.495,0,0,0-12.99,0V12.5a9.481,9.481,0,0,1-1.9,5.188A.5.5,0,0,0,4,18.5H20a.5.5,0,0,0,.391-.812Z'
        />
        <path
          fill={fill}
          d='M3,8.75a.656.656,0,0,1-.076,0,.749.749,0,0,1-.671-.821A9.579,9.579,0,0,1,4.618,2.5.75.75,0,0,1,5.743,3.5a8.1,8.1,0,0,0-2,4.579A.75.75,0,0,1,3,8.75Z'
        />
        <path
          fill={fill}
          d='M21,8.75a.75.75,0,0,1-.745-.675,8.1,8.1,0,0,0-2-4.579A.75.75,0,0,1,19.382,2.5a9.579,9.579,0,0,1,2.364,5.421.749.749,0,0,1-.671.821A.656.656,0,0,1,21,8.75Z'
        />
        <path
          fill={fill}
          d='M14.164,19.751a.5.5,0,0,0-.434-.251H10.27a.5.5,0,0,0-.432.752,2.5,2.5,0,0,0,4.324,0A.5.5,0,0,0,14.164,19.751Z'
        />
      </g>
    </svg>
  )
}

export default IconBellOn
