import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconMenuLeftSquare = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21,6.375v11.25Q21,21,17.625,21H6.375Q3,21,3,17.625V6.375Q3,3,6.375,3h11.25Q21,3,21,6.375Z'
        />
        <path
          fill={fill}
          d='M16,9.75H8a.75.75,0,0,1,0-1.5h8a.75.75,0,0,1,0,1.5ZM16.75,12a.75.75,0,0,0-.75-.75H8a.75.75,0,0,0,0,1.5h8A.75.75,0,0,0,16.75,12Zm-4,3a.75.75,0,0,0-.75-.75H8a.75.75,0,0,0,0,1.5h4A.75.75,0,0,0,12.75,15Z'
        />
      </g>
    </svg>
  )
}

export default IconMenuLeftSquare
