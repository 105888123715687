import { createSlice, Draft } from '@reduxjs/toolkit'
import { ISubscription, ISubscriptionReq } from '../../interfaces/subscriptions'

interface State {
  data: ISubscriptionReq | null
}

const initialState: State = {
  data: null,
}

export const subscriptionsSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscription: (state: Draft<State>, { payload }) => {
      state.data = payload.data
    },
    clearSubscription: (state: Draft<State>) => {
      state.data = null
    },
  },
})

export const { setSubscription, clearSubscription } = subscriptionsSlice.actions

export default subscriptionsSlice.reducer
