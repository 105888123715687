import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import styles from './AdminHeader.module.scss'
import Search from 'antd/lib/input/Search'
import { useNavigate } from 'react-router-dom'
import { shortDateFormat } from '../../../utils/time'
import { useMessage } from '../../../hooks/useMessage'
import HeaderLogo from '../../ui/headerLogo/HeaderLogo'
import { routersPath } from '../../../router/routes-path'
import { useAppDispatch } from '../../../store/hooks'
import BellNotification from '../../ui/BellNotification/BellNotification'
import { useSubscriptionSelector, useUserSelector } from '../../../store/selectors/auth'
import { AdminProfileDrop } from '../../../menu/dropdown/adminProfile/adminProfile'
import { getFirstNotifications } from '../../../store/notifications/thunks'
import { IconLightbulb, IconMoon, IconSearchText } from '../../../utils/icons'
import { useThemeSelector } from '../../../store/selectors/theme'
import { setTheme } from '../../../store/theme/reducer'
import PayBtn from '../../ui/PayBtn/PayBtn'
import { Switch } from 'antd'
import { useCrmInfoSelector, useEndSubscription } from '../../../store/selectors/crmInfo'
import SubscriptionBtn from './components/SubscriptionBtn/SubscriptionBtn'

const AdminHeader: React.FC = () => {
  const [search, setSearch] = useState('')
  const { error } = useMessage()
  const navigate = useNavigate()
  const user = useUserSelector()
  const crm = useCrmInfoSelector()
  const { data: subscriptions } = useSubscriptionSelector()
  const dispatch = useAppDispatch()
  const theme = useThemeSelector()
  const isEndSubscription = useEndSubscription()

  const onSearch = useCallback(() => {
    if (search) {
      navigate(routersPath.BASES_CLIENT_FIND, {
        state: {
          search: search,
        },
      })
      setSearch('')
    }
  }, [search])

  useEffect(() => {
    dispatch(getFirstNotifications()).then()
  }, [])

  useEffect(() => {
    const checkNotifications = () => {
      if (isEndSubscription)
        return `Подписка на CRM окончена ${shortDateFormat(subscriptions?.active?.endDate || '')}`
      if (
        moment()
          .add(7, 'day')
          .isAfter(moment(subscriptions?.active?.endDate ?? ''))
      )
        return `Подписка скоро закончиться. Дата окончания: ${shortDateFormat(
          subscriptions?.active?.endDate ?? '',
        )}`
      return null
    }
    const isNotification = checkNotifications()
    if (isNotification) error(isNotification, 'ОПЛАТИТЕ CRM')
  }, [subscriptions])

  const label = useMemo(() => {
    if (!crm?.isActive) return <div className={styles.subscription}>СRM НЕ АКТИВНА</div>
    if (isEndSubscription) return <div className={styles.subscription}>ОПЛАТИТЕ CRM</div>
    if (
      moment()
        .add(7, 'day')
        .isAfter(moment(subscriptions?.active?.endDate ?? ''))
    )
      return (
        <div className={styles.subscription}>{`Подписка до: ${shortDateFormat(
          subscriptions?.active?.endDate ?? '',
        )}`}</div>
      )
    return (
      <div className={styles.status}>{`Подписка до: ${shortDateFormat(
        subscriptions?.active?.endDate ?? '',
      )}`}</div>
    )
  }, [crm, subscriptions])

  const onChange = (checked: boolean) => {
    dispatch(setTheme({ theme: checked ? 'dark' : 'light' }))
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <span className={styles.logo}>
          <HeaderLogo />
        </span>

        {label}
        <SubscriptionBtn />
        <PayBtn />

        <div className={styles.search}>
          <Search
            className='input-search'
            placeholder='Поиск клиента'
            allowClear
            onSearch={onSearch}
            value={search}
            enterButton={<IconSearchText width='22' height='22' fill='var(--color-main)' />}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {user && (
          <div className={styles.menu}>
            <div className={styles.switcher}>
              <Switch
                defaultChecked={theme === 'dark'}
                onChange={onChange}
                checkedChildren={<IconMoon width='16' height='16' fill='var(--color-mainBg)' />}
                unCheckedChildren={
                  <IconLightbulb width='16' height='16' fill='var(--color-main)' />
                }
              />
            </div>
            <BellNotification />
            <div className={styles.dropdown}>
              <AdminProfileDrop />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(AdminHeader)
