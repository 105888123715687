import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconDocument = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M6,21a2.652,2.652,0,0,1-3-3V6A2.652,2.652,0,0,1,6,3h8a2.652,2.652,0,0,1,3,3V19a2,2,0,0,0,2,2Z'
        />
        <path
          fill={fill}
          d='M17,10h2.5A1.5,1.5,0,0,1,21,11.5V19a2.015,2.015,0,0,1-2,2,2.006,2.006,0,0,1-2-2Z'
        />
        <path
          fill={fill}
          d='M13,12.75H7a.75.75,0,0,1,0-1.5h6a.75.75,0,0,1,0,1.5ZM10.75,8A.75.75,0,0,0,10,7.25H7a.75.75,0,0,0,0,1.5h3A.75.75,0,0,0,10.75,8Zm3,8a.75.75,0,0,0-.75-.75H7a.75.75,0,0,0,0,1.5h6A.75.75,0,0,0,13.75,16Z'
        />
      </g>
    </svg>
  )
}

export default IconDocument
