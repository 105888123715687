import { SubscriptionType } from '../../../enums/subscription'
import { subscriptionMapper } from '../../../utils/mappers'
export interface ISubscriptionListItem {
  type: SubscriptionType
  desc: string
}

export const subscriptionList: ISubscriptionListItem[] = [
  {
    type: SubscriptionType.FIRST,
    desc: 'Основной функционал CRM',
  },
  {
    type: SubscriptionType.SECOND,
    desc: `Тариф ${subscriptionMapper(SubscriptionType.FIRST)} + Доступ старшего к статистике`,
  },
  {
    type: SubscriptionType.THIRD,
    desc: `Тариф ${subscriptionMapper(SubscriptionType.SECOND)} + Доступ бухгалтера к статистике`,
  },
]
