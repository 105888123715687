import { notification } from 'antd'

interface IUseMessage {
  success(msg: string, title?: string, duration?: number): void
  error(msg: string, title?: string): void
}

export const useMessage = (): IUseMessage => {
  const success = (msg?: string, title?: string, duration?: number) => {
    notification.success({
      message: title ?? 'Выполнено',
      description: msg ?? '',
      duration: duration ?? 3,
      placement: 'bottomLeft',
      getContainer: () => document.getElementById('App') || document.body,
    })
  }

  const error = (msg: string, title?: string) => {
    notification.error({
      message: title ?? 'Возникла ошибка',
      description: msg ?? '',
      duration: 5,
      placement: 'bottomLeft',
      getContainer: () => document.getElementById('App') || document.body,
    })
  }

  return { success, error }
}
