import React, { DetailedHTMLProps, ReactNode, useMemo } from 'react'
import styles from './BadgeUi.module.scss'
import cn from 'classnames'

interface Props extends DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  children: ReactNode | string
  color?: 'blue' | 'green' | 'red'
}

const BadgeUi: React.FC<Props> = ({ children, color = 'blue', className }) => {
  const getColor = useMemo(() => {
    switch (color) {
      case 'blue':
        return styles.blue
      case 'green':
        return styles.green
      case 'red':
        return styles.red
      default:
        return styles.blue
    }
  }, [color])
  return <span className={cn(styles.container, getColor, className)}>{children}</span>
}

export default BadgeUi
