import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconFolderCheck = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21,10v7a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V7A2.652,2.652,0,0,1,6,4h4l3,3h5A2.652,2.652,0,0,1,21,10Z'
        />
        <path
          fill={fill}
          d='M11.167,15.917a.747.747,0,0,1-.53-.22L8.97,14.03a.75.75,0,0,1,1.06-1.06l1.137,1.136,2.8-2.8a.75.75,0,0,1,1.06,1.061L11.7,15.7A.747.747,0,0,1,11.167,15.917Z'
        />
      </g>
    </svg>
  )
}

export default IconFolderCheck
