import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
  onClick?: () => void
}

const IconFileExport = ({ width = '24px', height = '24px', fill = '#000', onClick }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      onClick={onClick}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M17,9a2.652,2.652,0,0,1-3-3V3H8A2.652,2.652,0,0,0,5,6V18a2.652,2.652,0,0,0,3,3h9a2.652,2.652,0,0,0,3-3V9Z'
        />
        <path fill={fill} d='M17,9h3L14,3V6A2.652,2.652,0,0,0,17,9Z' />
        <path
          fill={fill}
          d='M14.692,15.287a.751.751,0,0,0,0-.574.771.771,0,0,0-.162-.244l-2-2a.75.75,0,0,0-1.06,1.06l.719.72H9a.75.75,0,0,0,0,1.5h3.189l-.719.72a.75.75,0,1,0,1.06,1.06l2-2A.755.755,0,0,0,14.692,15.287Z'
        />
      </g>
    </svg>
  )
}

export default IconFileExport
