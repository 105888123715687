import React, { useState } from 'react'
import MsgList from '../MsgList/MsgList'
import { IconBellOn } from '../../../utils/icons'
import styles from './BellNotification.module.scss'
import { useNotificationsSelector } from '../../../store/selectors/notifications'

const BellNotification: React.FC = () => {
  const { newMsg } = useNotificationsSelector()
  const [isShow, setIsShow] = useState(false)

  const showHandler = () => {
    setIsShow((prev) => !prev)
  }

  return (
    <>
      <div className={styles.container}>
        <span className={styles.bell} onClick={showHandler}>
          <IconBellOn width='22' height='22' fill='var(--color-main)' />
        </span>
        {newMsg > 0 && (
          <div className={styles.label} onClick={showHandler}>
            <span>{newMsg}</span>
          </div>
        )}
        {isShow && (
          <>
            <MsgList showHandler={showHandler} />
            <div className={styles.bg} onClick={showHandler}></div>
          </>
        )}
      </div>
    </>
  )
}

export default BellNotification
