import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconBriefcase = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21,11.95V18a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V11.94a2.876,2.876,0,0,0,1.13.8,26.916,26.916,0,0,0,4.37,1.3,1.029,1.029,0,0,0,1.04-.46A3.013,3.013,0,0,1,12,12.18a3.013,3.013,0,0,1,2.46,1.4,1.052,1.052,0,0,0,1.05.46,26.458,26.458,0,0,0,4.36-1.3A3.037,3.037,0,0,0,21,11.95Z'
        />
        <path
          fill={fill}
          d='M21,9v2.95a3.037,3.037,0,0,1-1.13.79,26.458,26.458,0,0,1-4.36,1.3,1.052,1.052,0,0,1-1.05-.46A3.013,3.013,0,0,0,12,12.18a3.013,3.013,0,0,0-2.46,1.4,1.029,1.029,0,0,1-1.04.46,26.916,26.916,0,0,1-4.37-1.3A2.876,2.876,0,0,1,3,11.94V9A2.652,2.652,0,0,1,6,6H18A2.652,2.652,0,0,1,21,9Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M16.75,4.5V6h-1.5V4.5A.248.248,0,0,0,15,4.25H9a.248.248,0,0,0-.25.25V6H7.25V4.5A1.752,1.752,0,0,1,9,2.75h6A1.758,1.758,0,0,1,16.75,4.5Z'
        />
        <path fill={fill} d='M12,16a1,1,0,0,1-.008-2H12a1,1,0,0,1,0,2Z' />
      </g>
    </svg>
  )
}

export default IconBriefcase
