import React from 'react'
import { Tooltip, TooltipProps } from 'antd'

const TooltipUi: React.FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <Tooltip {...props} getPopupContainer={() => document.getElementById('App') || document.body}>
      {children}
    </Tooltip>
  )
}

export default TooltipUi
