import { ReloadOutlined } from '@ant-design/icons'
import React, { useCallback } from 'react'
import { IconRotateCircle } from '../../../utils/icons'
import styles from './PayModal.module.scss'

interface Props {
  setReload: React.Dispatch<React.SetStateAction<number>>
}

const ApiError: React.FC<Props> = ({ setReload }) => {
  const reloadHandler = useCallback(() => {
    setReload((prev) => prev + 1)
  }, [setReload])

  return (
    <div className={styles.errorWrapper}>
      <div className={styles.header}>Ошибка</div>
      <div>
        Произла ошибка сервиса.
        <br /> Нажмите на кнопку обновить ниже.
      </div>
      <div className={styles.reload} onClick={reloadHandler}>
        <IconRotateCircle width='54' height='54' fill='var(--color-main)' />
      </div>
    </div>
  )
}

export default ApiError
