import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconExchangeHorizontal = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M16,20a1,1,0,0,1-.707-1.707L18.586,15H6a1,1,0,0,1,0-2H21a1,1,0,0,1,.707,1.707l-5,5A1,1,0,0,1,16,20Z'
        />
        <path
          fill={fill}
          d='M18,10H3a1,1,0,0,1-.707-1.707l5-5A1,1,0,0,1,8.707,4.707L5.414,8H18a1,1,0,0,1,0,2Z'
        />
      </g>
    </svg>
  )
}

export default IconExchangeHorizontal
