import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconScanner = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21,8.75A.75.75,0,0,1,20.25,8V4.5c0-.589-.161-.75-.75-.75H16a.75.75,0,0,1,0-1.5h3.5A2.067,2.067,0,0,1,21.75,4.5V8A.75.75,0,0,1,21,8.75ZM3.75,8V4.5c0-.589.161-.75.75-.75H8a.75.75,0,0,0,0-1.5H4.5A2.067,2.067,0,0,0,2.25,4.5V8a.75.75,0,0,0,1.5,0Zm5,13A.75.75,0,0,0,8,20.25H4.5c-.589,0-.75-.161-.75-.75V16a.75.75,0,0,0-1.5,0v3.5A2.067,2.067,0,0,0,4.5,21.75H8A.75.75,0,0,0,8.75,21Zm13-1.5V16a.75.75,0,0,0-1.5,0v3.5c0,.589-.161.75-.75.75H16a.75.75,0,0,0,0,1.5h3.5A2.067,2.067,0,0,0,21.75,19.5Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M17.5,12v3.12a2.147,2.147,0,0,1-2.38,2.38H8.88A2.147,2.147,0,0,1,6.5,15.12V12Z'
        />
        <path fill={fill} d='M19,12.75H5a.75.75,0,0,1,0-1.5H19a.75.75,0,0,1,0,1.5Z' />
        <path
          fill={fill}
          d='M17,9.75A.75.75,0,0,1,16.25,9V8.88c0-.845-.285-1.13-1.13-1.13H8.88c-.845,0-1.13.285-1.13,1.13V9a.75.75,0,0,1-1.5,0V8.88A2.394,2.394,0,0,1,8.88,6.25h6.24a2.394,2.394,0,0,1,2.63,2.63V9A.75.75,0,0,1,17,9.75Z'
        />
      </g>
    </svg>
  )
}

export default IconScanner
