import React from 'react'
import { Menu } from 'antd'
import DropdownUI from '../../../components/ui/DropDownUI/DropdownUi'
import styles from '../../../components/Header/ManagerHeader/ManagerHeader.module.scss'
import { useUserSelector } from '../../../store/selectors/auth'
import { NavLink } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import { useAppDispatch } from '../../../store/hooks'
import { authLogout } from '../../../store/auth/thunk'
import {
  IconClipboardCheck,
  IconClipboardEdit,
  IconClipboardList,
  IconLogOut,
  IconPrint,
  IconReceiptText,
  IconUser,
} from '../../../utils/icons'
import { useCrmPermission } from '../../../store/selectors/crmInfo'

interface MenuClickEventHandler {
  key: string
}
export const ManagerProfileDrop = () => {
  const user = useUserSelector()
  const dispatch = useAppDispatch()
  const permissions = useCrmPermission()

  const handler = (e: MenuClickEventHandler | undefined) => {
    switch (e?.key) {
      case 'exit': {
        dispatch(authLogout())
        break
      }
      default:
        return
    }
  }

  const ManagerProfile = (
    <Menu
      onClick={handler}
      items={[
        {
          disabled: false,
          key: routersPath.HISTORY_PAGE,
          label: (
            <NavLink to={routersPath.HISTORY_PAGE}>
              <span>История</span>
            </NavLink>
          ),
          icon: <IconClipboardList width='20' height='20' fill='var(--color-main)' />,
        },
        {
          disabled: false,
          key: routersPath.TASK_PAGE,
          label: (
            <NavLink to={routersPath.TASK_PAGE}>
              <span>Текущая задача</span>
            </NavLink>
          ),
          icon: <IconClipboardCheck width='20' height='20' fill='var(--color-main)' />,
        },
        {
          disabled: !permissions.tests,
          key: routersPath.MANAGER_TESTS,
          label: (
            <NavLink to={routersPath.MANAGER_TESTS}>
              <span>Тесты</span>
            </NavLink>
          ),
          icon: <IconClipboardEdit width='20' height='20' fill='var(--color-main)' />,
        },
        {
          disabled: !permissions.scripts,
          key: routersPath.MANAGER_SCRIPTS,
          label: (
            <NavLink to={routersPath.MANAGER_SCRIPTS}>
              <span>Мои скрипты</span>
            </NavLink>
          ),
          icon: <IconReceiptText width='20' height='20' fill='var(--color-main)' />,
        },
        {
          disabled: !permissions.generator,
          key: routersPath.DOC_GEN,
          label: (
            <NavLink to={routersPath.DOC_GEN}>
              <span>Генератор документов</span>
            </NavLink>
          ),
          icon: <IconPrint width='20' height='20' fill='var(--color-main)' />,
        },
        {
          disabled: false,
          key: 'divider',
          type: 'divider',
        },
        {
          key: 'exit',
          label: <span>Выход</span>,
          icon: <IconLogOut width='20' height='20' fill='var(--color-main)' />,
        },
      ].filter((el) => !el.disabled)}
    />
  )
  return (
    <DropdownUI menu={ManagerProfile}>
      <span className={styles.backdrop}>
        <span className={styles.icon}>
          <IconUser width='22' height='22' fill='var(--color-main)' />
        </span>
        {user?.nickName ? user.nickName : 'Менеджер'}
      </span>
    </DropdownUI>
  )
}
