import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconHashtag = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M13.5,21a1.025,1.025,0,0,1-.244-.03,1,1,0,0,1-.727-1.212l4-16a1,1,0,1,1,1.94.484l-4,16A1,1,0,0,1,13.5,21Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M6.5,21a1.025,1.025,0,0,1-.244-.03,1,1,0,0,1-.727-1.212l4-16a1,1,0,1,1,1.94.484l-4,16A1,1,0,0,1,6.5,21Z'
        />
        <path fill={fill} d='M21,9.5H5a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z' />
        <path fill={fill} d='M19,16.5H3a1,1,0,0,1,0-2H19a1,1,0,0,1,0,2Z' />
      </g>
    </svg>
  )
}

export default IconHashtag
