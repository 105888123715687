import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconDollarReceiveSquare = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M20.582,9.213A.3.3,0,0,1,21,9.486V17.62C21,19.87,19.85,21,17.62,21H6.38C4.1,21,3,19.87,3,17.62V6.38C3,4.12,4.1,3,6.38,3h8.134a.3.3,0,0,1,.273.418A4.545,4.545,0,0,0,14.6,5.96,4.489,4.489,0,0,0,18.038,9.4,4.552,4.552,0,0,0,20.582,9.213Z'
        />
        <path
          fill={fill}
          d='M21.53,4.47a.749.749,0,0,0-1.06,0l-.72.719V2a.75.75,0,0,0-1.5,0V5.189l-.72-.719a.75.75,0,0,0-1.06,1.06l2,2a.751.751,0,0,0,1.06,0l2-2A.749.749,0,0,0,21.53,4.47Z'
        />
        <path
          fill={fill}
          d='M14.972,14.017a2.624,2.624,0,0,0-1.986-2.545l-1.608-.4a1.12,1.12,0,0,1-.617-.405,1.1,1.1,0,0,1-.233-.685A1.123,1.123,0,0,1,11.65,8.861h.7a1.125,1.125,0,0,1,1.116,1,.75.75,0,1,0,1.49-.167,2.617,2.617,0,0,0-2.228-2.3V7a.75.75,0,0,0-1.5,0v.4a2.618,2.618,0,0,0-.214,5.124l1.608.4a1.123,1.123,0,0,1-.272,2.212h-.7a1.125,1.125,0,0,1-1.116-1,.75.75,0,1,0-1.49.167A2.619,2.619,0,0,0,11.228,16.6V17a.75.75,0,0,0,1.5,0v-.4a2.608,2.608,0,0,0,2.244-2.585Z'
        />
      </g>
    </svg>
  )
}

export default IconDollarReceiveSquare
