import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconPercentCircle = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <circle fill={fill} opacity={0.3} cx='12' cy='12' r='10' />
        <path
          fill={fill}
          d='M8,16.75a.75.75,0,0,1-.53-1.28l8-8a.75.75,0,0,1,1.06,1.06l-8,8A.744.744,0,0,1,8,16.75Zm8-2A1.25,1.25,0,1,0,14.75,16,1.25,1.25,0,0,0,16,14.75Zm-5.5-5.5A1.25,1.25,0,1,0,9.25,10.5,1.25,1.25,0,0,0,10.5,9.25Z'
        />
      </g>
    </svg>
  )
}

export default IconPercentCircle
