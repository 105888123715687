import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconShieldSlash = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M5.87,18.13A10.4,10.4,0,0,1,3,10.89V5a29.794,29.794,0,0,0,9.02-3,26.845,26.845,0,0,0,7.33,2.65Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M8.523,20.332A16.084,16.084,0,0,0,12,22c3.33-1.11,9-4.44,9-11.11V8.207a.5.5,0,0,0-.854-.353l-11.7,11.7A.5.5,0,0,0,8.523,20.332Z'
        />
        <path
          fill={fill}
          d='M3,21.75a.75.75,0,0,1-.53-1.28l18-18a.75.75,0,0,1,1.06,1.06l-18,18A.744.744,0,0,1,3,21.75Z'
        />
      </g>
    </svg>
  )
}

export default IconShieldSlash
