import { ExclamationCircleOutlined } from '@ant-design/icons'
import React, { useMemo } from 'react'
import { useDefaultPath } from '../../hooks/useDefautPath'
import { routersPath } from '../../router/routes-path'
import { useUserSelector } from '../../store/selectors/auth'
import styles from './NotFound.module.scss'
import BoxUi from '../../components/ui/BoxUi/BoxUi'

const NotFound: React.FC = () => {
  const user = useUserSelector()
  const redirect = useDefaultPath()
  const getRedirect = useMemo(() => {
    return user ? redirect : routersPath.LOGIN_PAGE
  }, [user])
  return (
    <div className={styles.container}>
      <BoxUi className={styles.card}>
        <>
          <ExclamationCircleOutlined className={styles.icon} />
          <div className={styles.title}>Доступ ограничен</div>
          <a className={styles.link} href={getRedirect}>
            на главную
          </a>
        </>
      </BoxUi>
    </div>
  )
}

export default NotFound
