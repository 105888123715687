import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconCreditCardTimes = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path fill={fill} d='M20,7V9H2V7A2.652,2.652,0,0,1,5,4H17A2.652,2.652,0,0,1,20,7Z' />
        <path
          fill={fill}
          opacity={0.3}
          d='M20,9v2.45a.3.3,0,0,1-.31.3.5.5,0,0,1-.12-.02A5.42,5.42,0,0,0,18,11.5,5.51,5.51,0,0,0,12.5,17a5.322,5.322,0,0,0,.04.69.312.312,0,0,1-.31.31H5a2.652,2.652,0,0,1-3-3V9Z'
        />
        <path fill={fill} d='M9,14.75H6a.75.75,0,0,1,0-1.5H9a.75.75,0,0,1,0,1.5Z' />
        <path
          fill={fill}
          d='M18,13a4,4,0,1,0,4,4A4,4,0,0,0,18,13Zm1.35,4.65a.483.483,0,0,1,0,.7.483.483,0,0,1-.7,0L18,17.7l-.65.65a.483.483,0,0,1-.7,0,.483.483,0,0,1,0-.7L17.3,17l-.65-.65a.495.495,0,1,1,.7-.7l.65.65.65-.65a.495.495,0,1,1,.7.7L18.7,17Z'
        />
      </g>
    </svg>
  )
}

export default IconCreditCardTimes
