import React, { useCallback } from 'react'
import { ITransaction } from './payment.interface'
import styles from './PayModal.module.scss'
import ButtonUi from '../../ui/ButtonUi/ButtonUi'
import { fullDateFormat } from '../../../utils/time'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useMessage } from '../../../hooks/useMessage'
import axiosInstance from '../../../config/axios'
import { serverPath } from '../../../constants/api-endpoints'
import { errorMapper } from '../../../utils/errorMapper'
import { ErrorMessages } from '../../../constants/errors'
import QRCode from 'react-qr-code'
import { IconCalendar, IconDollar, IconLinkAlt, IconWallet } from '../../../utils/icons'
import WithLabel from '../../ui/WithLabel/WithLabel'
import { Input, Button } from 'antd'
import TooltipUi from '../../ui/TooltipUi/TooltipUi'

interface Props {
  setReload: React.Dispatch<React.SetStateAction<number>>
  transaction: ITransaction
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const TransactionCard: React.FC<Props> = ({ setReload, transaction, setLoading }) => {
  const { success, error } = useMessage()

  const reloadHandler = useCallback(() => {
    setReload((prev) => prev + 1)
  }, [setReload])

  const deleteHandler = useCallback(() => {
    setLoading(true)
    axiosInstance
      .delete(serverPath.BILLING + '/' + transaction.id)
      .then(() => {
        reloadHandler()
        success('Заявка удалена')
      })
      .catch((e) => {
        error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
      })
      .finally(() => setLoading(false))
  }, [transaction])

  return (
    <div className={styles.transaction}>
      <div className={styles.header}>Открыта заявка на оплату:</div>

      <div className={styles.item}>
        <WithLabel
          label={'Сумма оплаты:'}
          icon={<IconDollar width='18' height='18' fill='var(--color-main)' />}
        >
          <Input
            type='text'
            value={`${transaction.amount} ${transaction.currency} ${transaction.payway}`}
            disabled
            className={styles.input}
          />
        </WithLabel>
      </div>

      <div className={styles.item}>
        <WithLabel
          label={'Кошелек для оплаты:'}
          icon={<IconWallet width='18' height='18' fill='var(--color-main)' />}
        >
          <Input.Group compact className={styles.copyInput}>
            <Input type='text' value={`${transaction.wallet}`} disabled />
            <TooltipUi title='Скопировать адрес кошелька'>
              <CopyToClipboard
                text={transaction.wallet}
                onCopy={() => success(`Адрес кошелька скопирован`)}
              >
                <Button icon={<IconLinkAlt width='22' height='22' fill='var(--color-main)' />} />
              </CopyToClipboard>
            </TooltipUi>
          </Input.Group>
        </WithLabel>
      </div>

      <div className={styles.item}>
        <div className={styles.qr}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={transaction.wallet}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>

      <WithLabel
        label={'Дата создания заявки:'}
        icon={<IconCalendar width='18' height='18' fill='var(--color-main)' />}
      >
        <Input
          type='text'
          value={`${fullDateFormat(transaction.createdAt)}`}
          disabled
          className={styles.input}
        />
      </WithLabel>

      <div className={styles.actions}>
        <ButtonUi color='red' onClick={deleteHandler}>
          Отменить заявку
        </ButtonUi>
      </div>
    </div>
  )
}

export default TransactionCard
