import { AppDispatch } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { serverPath } from '../../constants/api-endpoints'
import { ICrmInfoRes } from '../../interfaces/crmInfo'
import { setCrmInfo } from './reducer'

export const getCrmInfo = () => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<ICrmInfoRes> = await axiosInstance.get(serverPath.CRM_INFO)
    dispatch(setCrmInfo(data))
  } catch (e) {
    console.log(e)
  }
}
