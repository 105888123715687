import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconHandHoldingDollar = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          d='M14.75,9.315a2.438,2.438,0,0,0-1.844-2.363l-1.448-.359a.93.93,0,0,1-.514-.338.92.92,0,0,1-.194-.57.935.935,0,0,1,.935-.935h.63a.936.936,0,0,1,.93.834.75.75,0,1,0,1.49-.168,2.433,2.433,0,0,0-2-2.127V3a.75.75,0,0,0-1.5,0v.3a2.434,2.434,0,0,0-.136,4.752l1.447.359a.938.938,0,0,1,.709.908.941.941,0,0,1-.935.935h-.63a.936.936,0,0,1-.93-.834.75.75,0,1,0-1.49.168,2.437,2.437,0,0,0,1.965,2.124V12a.75.75,0,0,0,1.5,0v-.291a2.429,2.429,0,0,0,2.02-2.394Z'
        />
        <path
          fill={fill}
          d='M5,21H3a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1H5a1,1,0,0,1,1,1v4A1,1,0,0,1,5,21Z'
        />
        <path
          fill={fill}
          opacity={0.3}
          d='M21,16.36a1.322,1.322,0,0,1-.5,1.04l-3.4,2.72a3.989,3.989,0,0,1-2.5.88H9a2.006,2.006,0,0,1-2-2V17a2.006,2.006,0,0,1,2-2h5.12a1.125,1.125,0,1,1,0,2.25H11a.75.75,0,0,0,0,1.5h3.12a2.633,2.633,0,0,0,2.59-2.18l2.27-1.36a1.322,1.322,0,0,1,.68-.19A1.34,1.34,0,0,1,21,16.36Z'
        />
      </g>
    </svg>
  )
}

export default IconHandHoldingDollar
