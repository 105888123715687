import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconUserChange = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M16.5,7.5h-2a2.823,2.823,0,0,0-3,3.009,1.817,1.817,0,0,0,2,1.991h4a1.817,1.817,0,0,0,2-1.991A2.823,2.823,0,0,0,16.5,7.5Z'
        />
        <circle fill={fill} cx='15.505' cy='3.75' r='2.25' />
        <path
          fill={fill}
          opacity={0.3}
          d='M8.5,17.5h-2a2.823,2.823,0,0,0-3,3.009,1.817,1.817,0,0,0,2,1.991h4a1.817,1.817,0,0,0,2-1.991A2.823,2.823,0,0,0,8.5,17.5Z'
        />
        <circle fill={fill} cx='7.505' cy='13.75' r='2.25' />
        <path
          fill={fill}
          d='M3,11.855a.746.746,0,0,1-.746-.831A9.632,9.632,0,0,1,7.61,3.382a.75.75,0,0,1,1.08.674V7.105a.75.75,0,1,1-1.5,0V5.364a8.145,8.145,0,0,0-3.445,5.823A.749.749,0,0,1,3,11.855Z'
        />
        <path
          fill={fill}
          d='M16.06,20.806a.751.751,0,0,1-.75-.75V17.005a.75.75,0,0,1,1.5,0v1.742a8.145,8.145,0,0,0,3.445-5.823.749.749,0,1,1,1.49.163,9.633,9.633,0,0,1-5.355,7.642A.753.753,0,0,1,16.06,20.806Z'
        />
      </g>
    </svg>
  )
}

export default IconUserChange
