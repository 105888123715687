import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconChartBar = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <circle fill={fill} opacity={0.3} cx='12' cy='12' r='10' />
        <path
          fill={fill}
          d='M10.833,15.083a.751.751,0,0,1-.53-.219L7.97,12.53a.75.75,0,0,1,1.06-1.06l1.8,1.8L14.97,9.136A.75.75,0,0,1,16.03,10.2l-4.666,4.667A.751.751,0,0,1,10.833,15.083Z'
        />
      </g>
    </svg>
  )
}

export default IconChartBar
