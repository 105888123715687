import React, { useEffect } from 'react'
import './styles/App.scss'
import ruRu from 'antd/es/locale/ru_RU'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import 'moment/locale/ru'
import { RouterComponent } from './router/RouterComponent'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { useThemeSelector } from './store/selectors/theme'
import { useCrmInfoSelector } from './store/selectors/crmInfo'
import { authLogout } from './store/auth/thunk'
import { useAppDispatch } from './store/hooks'

moment.locale('ru')

const App: React.FC = () => {
  const theme = useThemeSelector()
  const crm = useCrmInfoSelector()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!crm.isActive) {
      dispatch(authLogout())
    }
  }, [crm])

  return (
    <div id={'App'} className={`App ${theme}`}>
      <ConfigProvider locale={ruRu}>
        <RouterComponent />
      </ConfigProvider>
    </div>
  )
}

export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)
