import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconCheckCircle = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path fill={fill} opacity={0.3} d='M21,21.75H3a.75.75,0,0,1,0-1.5H21a.75.75,0,0,1,0,1.5Z' />
        <path
          fill={fill}
          opacity={0.3}
          d='M12.5,18h-1A1.326,1.326,0,0,1,10,16.5V4.5A1.326,1.326,0,0,1,11.5,3h1A1.326,1.326,0,0,1,14,4.5v12A1.326,1.326,0,0,1,12.5,18Z'
        />
        <path
          fill={fill}
          d='M19.5,18h-1A1.326,1.326,0,0,1,17,16.5v-8A1.326,1.326,0,0,1,18.5,7h1A1.326,1.326,0,0,1,21,8.5v8A1.326,1.326,0,0,1,19.5,18Z'
        />
        <path
          fill={fill}
          d='M5.5,18h-1A1.326,1.326,0,0,1,3,16.5v-5A1.326,1.326,0,0,1,4.5,10h1A1.326,1.326,0,0,1,7,11.5v5A1.326,1.326,0,0,1,5.5,18Z'
        />
      </g>
    </svg>
  )
}

export default IconCheckCircle
