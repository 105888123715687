import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconFolderSearch = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d='M21,10v7a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V7A2.652,2.652,0,0,1,6,4h4l3,3h5A2.652,2.652,0,0,1,21,10Z'
        />
        <path
          fill={fill}
          d='M15.549,16.488,14,14.938A3.684,3.684,0,1,0,12.938,16l1.55,1.55a.75.75,0,0,0,1.061-1.061Zm-6.78-3.553A2.167,2.167,0,1,1,10.935,15.1,2.168,2.168,0,0,1,8.769,12.935Z'
        />
      </g>
    </svg>
  )
}

export default IconFolderSearch
