import React from 'react'

interface Props {
  width: string
  height: string
  fill: string
}

const IconTimesCircle = ({ width = '24px', height = '24px', fill = '#000' }: Props) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g>
        <circle fill={fill} opacity={0.3} cx='12' cy='12' r='10' />
        <path
          fill={fill}
          d='M13.061,12,15.53,9.53a.75.75,0,0,0-1.06-1.06L12,10.939,9.53,8.47A.75.75,0,0,0,8.47,9.53L10.939,12,8.47,14.47a.75.75,0,1,0,1.06,1.06L12,13.061l2.47,2.469a.75.75,0,0,0,1.06-1.06Z'
        />
      </g>
    </svg>
  )
}

export default IconTimesCircle
