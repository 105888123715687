import { createSlice, Draft } from '@reduxjs/toolkit'
import { BaseDefaultStatusesColumn } from '../../enums/base'
import { ISettingsStatus } from '../../interfaces/settings'
import { statusMapper } from '../../utils/mappers'

export interface ISettings {
  statuses: ISettingsStatus[]
}

const initialState: ISettings = {
  statuses: Object.values(BaseDefaultStatusesColumn).map((el) => ({
    name: el,
    mapName: statusMapper(el),
  })),
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setStatuses: (state: Draft<ISettings>, { payload }) => {
      if (payload?.length) state.statuses = payload
    },
  },
})

export const { setStatuses } = settingsSlice.actions

export default settingsSlice.reducer
